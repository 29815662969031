<template>
  <v-container fluid class="ma-0 pa-0">
    <InputTransactionJournalV2
      selectedForm="add"
      :selectedJournal="selectedJournal"
      :unitList="unitList"
    />
  </v-container>
</template>

<script>
import Constant from '@/const';
import ConstantMixin from '@/mixin/constantMixin';

const InputTransactionJournalV2 = () =>
  import('./Journal/InputTransactionJournalV2.vue');
const moment = require('moment');
const axios = require('axios');

export default {
  name: 'NewJournal',
  mixins: [ConstantMixin],
  components: {
    InputTransactionJournalV2,
  },
  data() {
    return {
      selectedForm: null,
      selectedJournal: {
        journalDateRaw: '',
      },
      unitList: [],
    };
  },
  mounted() {
    this.getAllUnit();
  },
  beforeDestroy() {
    // clear stored form
    localStorage.removeItem('journalTransaction');
  },
  methods: {
    async getAllUnit() {
      axios
        .get(Constant.apiUrl.concat('/masterdata/unit'))
        .then((response) => {
          const unitDatas = response.data.data;
          this.unitList = unitDatas.map((item) => {
            return {
              id: item._id,
              name: item.detail.name,
            };
          });
        })
        .finally(() => {
          this.input = {
            transactionDate: this.selectedJournal.journalDateRaw,
            unit: this.unitList.find(
              (arr) => arr.name === this.selectedJournal.unit,
            ),
            ...this.selectedJournal,
          };
        });
    },
    defaultDate() {
      this.filter.endDate = moment(this.nowDate).format('YYYY-MM-DD');
      this.filter.startDate = moment(this.nowDate)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.$nextTick(() => {
        this.getPostedJournalDatas();
        this.getNotPostedJournalDatas();
        this.getUser();
        this.getAllUnit();
      });
    },
  },
};
</script>
