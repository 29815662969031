const moment = require('moment');
const stringSimilarity = require('string-similarity');

export default {
  data() {
    return {
      // Inventory transaction status
      inventory: {
        status: {
          requested: 'requested',
          accepted: 'accepted',
          rejected: 'rejected',
          resolved: 'resolved',
          received: 'received',
        },
        action: {
          mutasi: 'Mutasi',
          restock: 'Restok',
          transfer: 'Pemesanan',
          koreksi: 'Koreksi',
          koreksi_restok: 'Pembatalan faktur',
          opname: 'Stok Opname',
          consume: 'Penjualan',
          usage: 'Pemakaian Unit',
          kirim: 'Pengiriman',
          returned: 'Retur Pasien',
          syubhat_return: 'Retur Non Pasien',
          item_loan_in: 'Pinjaman Item Masuk',
          item_loan_out: 'Pinjaman Item Keluar',
          item_return_in: 'Pengembalian Item Masuk',
          item_return_out: 'Pengembalian Item Keluar',
        },
      },
      resource: {
        unit: [
          { text: 'Bungkus', defaultTo: ['puyer tidak terbagi', 'bungkus'] },
          { text: 'Hisap', defaultTo: [''] },
          {
            text: 'Kapsul',
            defaultTo: [
              'softgell kapsul',
              'kapsul keras',
              'kapsul lunak',
              'kapsul',
              'film coated caplet',
            ],
          },
          { text: 'Kumur', defaultTo: [''] },
          {
            text: 'ml',
            defaultTo: [
              'concentrate',
              'drop',
              'larutan',
              'mg/ 0.5 ml',
              'mixtur',
              'solutio',
            ],
          },
          {
            text: 'mg',
            defaultTo: [],
          },
          {
            text: 'Oleskan',
            defaultTo: [
              'emulgel',
              'gel',
              'krim',
              'lotion',
              'salep',
              'salep Kulit',
              'salep Mata',
            ],
          },
          {
            text: 'Semprot',
            defaultTo: ['inhalasi', 'inhaler', 'semprot Hidung'],
          },
          { text: 'sendok Makan', defaultTo: [''] },
          { text: 'sendok Obat', defaultTo: [''] },
          { text: 'sendok Sup', defaultTo: [''] },
          {
            text: 'Sendok Teh',
            defaultTo: ['emulsi', 'sirup', 'sirup kering', 'suspensi'],
          },
          {
            text: 'Tablet',
            defaultTo: [
              'enteric coated tablet',
              'film coated tablet',
              'kaplet',
              'kaplet coated',
              'kaplet kunyah',
              'kaplet salut selaput',
              'tablet',
              'tablet dispersible',
              'tablet effervescent',
              'tablet hisap',
              'tablet kunyah',
              'tablet oral',
              'tablet salut',
              'tablet salut gula',
              'tablet salut selaput',
              'tablet sublingual',
              'tablet vaginal',
            ],
          },
          {
            text: 'Tetes',
            defaultTo: [
              'tetes',
              'tetes hidung',
              'tetes mata',
              'tetes oral',
              'tetes telinga',
            ],
          },
          {
            text: 'Ampul',
            defaultTo: ['injeksi suspensi'],
          },
          {
            text: 'gr',
            defaultTo: ['serbuk'],
          },
          {
            text: 'ovula',
            defaultTo: ['ovula'],
          },
          {
            text: 'patch',
            defaultTo: ['patch'],
          },
          {
            text: 'Rectal Tube',
            defaultTo: ['rectal tube'],
          },
          {
            text: 'pcs',
            defaultTo: ['suppositoria'],
          },
          {
            text: 'TPM',
            defaultTo: ['infus'],
          },
          {
            text: 'unit',
            defaultTo: ['flex pen'],
          },
          {
            text: 'vial',
            defaultTo: ['serbuk injeksi'],
          },
        ],
        abvRoute: [
          {
            text: 'Per Oral',
            value: 'PO',
          },
          {
            text: 'Sublingual',
            value: 'SL',
          },
          {
            text: 'Intravena',
            value: 'IV',
          },
          {
            text: 'Intra Cutan',
            value: 'IC',
          },

          {
            text: 'Intramuscular',
            value: 'IM',
          },
          {
            text: 'Subcutan',
            value: 'SC',
          },
          {
            text: 'Transdermal',
            value: 'TD',
          },
          {
            text: 'Nasal (hidung)',
            value: 'NS',
          },
          {
            text: 'Inhalasi',
            value: 'IN',
          },
          {
            text: 'Okular (mata)',
            value: 'OC',
          },
          {
            text: 'Otic (telinga)',
            value: 'OT',
          },
          {
            text: 'Per Vagina',
            value: 'PV',
          },
          {
            text: 'Per Rectal',
            value: 'PR',
          },
          {
            text: 'Intratekal',
            value: 'IT',
          },
        ],
      },
    };
  },
  computed: {
    nowDate() {
      return moment().locale('id').format('DD/MM/YYYY');
    },
    nowDateFormat() {
      return moment().locale('id').format('YYYY-MM-DD');
    },
  },
  methods: {
    threeDigits(int) {
      // console.log(int);
      const str = int.toString();
      if (str.length === 3) {
        return str;
      }
      if (str.length === 2) {
        return `0${str}`;
      }
      return `00${str}`;
    },
    fourDigit(int) {
      const str = int.toString();
      if (str.length === 4) {
        return str;
      }
      if (str.length === 3) {
        return `0${str}`;
      }
      if (str.length === 2) {
        return `00${str}`;
      }
      return `000${str}`;
    },
    validateEmail(mail) {
      const testEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return testEmail.test(mail);
    },
    capitalize(str) {
      return str
        .split('')
        .map((c, i) => {
          return i === 0 ? c.toUpperCase() : c.toLowerCase();
        })
        .join('');
    },
    formatNumber(num) {
      if (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      }
      return num;
    },
    formatNumbertoCurr(num) {
      // console.log(num);
      if (Number.isNaN(num))
        return Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(0);
      return Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(num);
    },
    formatNumbertoCurrNoRp(num) {
      if (num === null) {
        num = '';
      } else if (num < 0) {
        num = Math.abs(num);

        const formattedNumber = num.toLocaleString('id-ID', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return `(${formattedNumber})`;
      }
      return num.toLocaleString('id-ID', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    formatNumberReport(num, isDebits) {
      let val = '';
      const isNegative = num < 0;

      if (num === null) {
        num = '';
      }

      if (!isDebits) {
        num = Math.abs(num);
        const formattedNumber = num.toLocaleString('id-ID', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        val = isNegative ? `${formattedNumber}` : `(${formattedNumber})`;
      } else if (isDebits) {
        num = Math.abs(num);
        const formattedNumber = num.toLocaleString('id-ID', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        val = isNegative ? `(${formattedNumber})` : `${formattedNumber}`;
      }
      return val;
    },
    formatPartialToDec(str) {
      if (str.includes('/')) {
        return (str.split('/')[0] / str.split('/')[1]).toFixed(3);
      }
      return 0;
    },
    roundTotal(total) {
      const mod = total % 1000;
      let add = mod >= 500 ? 1000 - mod : 500 - mod;
      if (mod === 0 || mod === 500) add = 0;
      return add;
    },
    maxDatePicker(days = 0) {
      return moment().add(days, 'days').locale('id').format('YYYY-MM-DD');
    },
    formatDate(date, divider = false) {
      if (!date) return null;
      if (moment(date).isValid())
        switch (divider) {
          case 'time':
            return moment(date).locale('id').format('HH:mm');
          case 'datetime':
            return moment(date)
              .locale('id')
              .format('DD MMMM YYYY [pukul] HH:mm:ss [WIB]');
          case 'simpledatetime':
            return moment(date)
              .locale('id')
              .format('DD MMM YYYY [pukul] HH:mm [WIB]');
          case 'simpledate':
            return moment(date).locale('id').format('DD MMM YYYY');
          case 'shortdatetime':
            return moment(date).locale('id').format('DD MMM YYYY [-] HH:mm');
          case 'daydate':
            return moment(date).locale('id').format('dddd, DD MMMM YYYY');
          case 'full':
            return moment(date).locale('id').format('DD/MM/YYYY HH:mm [WIB]');
          case 'fullnowib':
            return moment(date).locale('id').format('DD/MM/YYYY HH:mm');
          case 'log':
            return moment(date)
              .locale('id')
              .format('DD/MM/YYYY [pada] HH:mm [WIB]');
          case '/':
            return moment(date).locale('id').format('DD/MM/YYYY');
          case '-':
            return moment(date).locale('id').format('YYYY-MM-DD');
          case 'age':
            return moment().diff(moment(date), 'years');

          default:
            return moment(date).locale('id').format('D MMMM YYYY');
        }
      return '-';
    },
    formatFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return `${bytes.toFixed(dp)} ${units[u]}`;
    },
    formatedTime(status, data) {
      let tempTime = '';
      let hourTemp = '';
      let minuteTemp = '';
      let autofillTemp = '';
      switch (status) {
        case 'input':
          minuteTemp = data.split('').splice(2, 2).join('');
          hourTemp = data.split('').splice(0, 2).join('');
          tempTime = `${hourTemp}:${minuteTemp}`;
          break;
        case 'autofill':
          autofillTemp = data.replace(':', '');
          tempTime = autofillTemp;
          break;
        default:
          break;
      }
      return tempTime;
    },
    validateManualTime(data) {
      const error = {
        errorHour: false,
        errorMinute: false,
        errorDigit: false,
      };
      const minuteTemp = data.split('').splice(2, 2).join('');
      const hourTemp = data.split('').splice(0, 2).join('');
      if (+hourTemp > 23) {
        error.errorHour = true;
      }
      if (+minuteTemp > 59) {
        error.errorMinute = true;
      }
      if (data.length < 4) {
        error.errorDigit = true;
      }
      return {
        ...error,
      };
    },
    similarString(a, b) {
      // console.log(a, b);
      const matrix = [];

      // increment along the first column of each row
      let i;
      // eslint-disable-next-line no-cond-assign
      for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
      }

      // increment each column in the first row
      let j;
      for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
      }

      // Fill in the rest of the matrix
      for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
          if (b.charAt(i - 1) === a.charAt(j - 1)) {
            matrix[i][j] = matrix[i - 1][j - 1];
          } else {
            matrix[i][j] = Math.min(
              matrix[i - 1][j - 1] + 1, // substitution
              Math.min(
                matrix[i][j - 1] + 1, // insertion
                matrix[i - 1][j] + 1,
              ),
            ); // deletion
          }
        }
      }
      const res1 = matrix[b.length][a.length];
      const res2 = stringSimilarity.compareTwoStrings(a, b);
      return [res1, res2];
    },
    polyCodeByName(text) {
      switch (text.toLowerCase()) {
        case 'klinik penyakit dalam':
          return 1;
        case 'klinik anak':
          return 10;
        case 'klinik syaraf':
          return 11;
        case 'klinik jantung':
          return 12;
        case 'klinik tht':
          return 13;
        case 'klinik bedah umum':
          return 14;
        case 'klinik jiwa':
          return 15;
        case 'rehab medik':
          return 16;
        case 'klinik orthopedi':
          return 17;
        case 'klinik paru':
          return 18;
        case 'klinik bedah anak':
          return 19;
        case 'klinik mcu':
          return 65;
        case 'klinik geriatri':
          return 87;
        case 'klinik vaksinasi':
          return 88;
        case 'klinik gizi':
          return 80;
        case 'klinik gigi dan mulut':
          return 2;
        case 'klinik orthodentis':
          return 35;
        case 'klinik bedah mulut':
          return 36;
        case 'klinik tb dots':
          return 152;
        case 'klinik anestesi':
          return 153;
        case 'klinik urologi':
          return 169;
        case 'klinik bayi baru lahir':
          return 170;
        case 'klinik berhenti merokok':
          return 172;
        case 'klinik keluarga sakinah':
          return 171;
        case 'klinik bedah saraf':
          return 173;
        default:
          return 'Klinik';
      }
    },
    removeCodeDescription(code, description) {
      if (description && code) {
        return description.replace(code, '').trim().replace(/^-/, '').trim();
      }
      return description;
    },
  },
};
